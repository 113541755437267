import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid2 as MuiGrid, Button as MuiButton } from "@mui/material";
import { CloudDownload as MuiCloudDownloadIcon } from "@mui/icons-material";
/** Custom Components */
import CustomTable from "app/shared/ui/Table/Table";
import CustomDialog from "app/shared/ui/Dialog/Dialog";
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { Spinner } from "app/shared/ui/Loading/Spinner";
/** Services */
import {
  getOutputSubmissionsList,
  getOutputTable6BPopUp,
  getExportData,
  getOutputTable6B,
} from "app/services/outputService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/* Styles */
import { outputTableStyles } from "./OutputTable6A.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

const MapEnumDegreeArr = {
  PhDs: "PhDs",
  MDs: "MDs",
  DUAL_DEGREE_HOLDERS: "Dual-Degree Holders",
  OTHER_DEGREE_HOLDERS: "Other Degree Holders",
  TOTAL: "Total",
  MEAN: "Mean",
};

const degreeEnumMap = (degree) => {
  let mappedDegreeValue = MapEnumDegreeArr[degree];

  return mappedDegreeValue;
};
/**
 * Filter Table1 Pop up Data.
 *
 * @param data
 *   Response pop-up data received from API.
 */
const filterTable1PopUpData = (data) => {
  const response = {};
  // Check if data returned from API Call.
  if (data && data.length) {
    data.map((record) => {
      return (response[record["traineeDto"]["rowId"]] = {
        "#": record["traineeDto"]["rowId"] ?? "",
        name: record["traineeDto"]["fullName"] ?? "",
        sunetId: record["traineeDto"]["sunetId"] ?? "",
      });
    });
  }
  return response;
};

export const Table6B = (props) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [outputTableSixBEnumFilter, setOutputTableSixBEnumFilter] =
    useState(null);
  const [degreeCode, setDegreeCode] = useState(null);
  const [getYear, setYear] = useState(null);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { handleSubmissionUpdate } = useSelectedSubmission();
  const [popupData, setPopupData] = useState([]);
  const [table6BData, setTable6BData] = useState([]);
  const { setAlert, clearAlert } = useAlerts();
  const [loading, setLoading] = useState(true);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const selectedSubmission = getSelectedSubmission();
  const tableColumnLabel = "output.mainView.list.tableColumns.table6B";

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    handleSubmissionUpdate();
  };

  /**
   * Open Modal Box or Perform any other linking functionality.
   */
  const table1ModalOpenEventHandler = (
    rowId,
    outputTableSixBEnumFilter,
    year,
    degreeCode
  ) => {
    const [, { degreeTypeForSixBCount }] = degreeCode;
    setOpen(true);
    setOutputTableSixBEnumFilter(outputTableSixBEnumFilter);
    setDegreeCode(degreeTypeForSixBCount);
    setYear(year);
  };

  /**
   * Dialog Close Button Handler.
   */
  const handleCloseHandler = () => {
    setOpen(false);
  };

  /**
   * Filter Table Data.
   *
   * @param data
   *   Response data received from API.
   */
  const filterTableData = (data) => {
    return filterCountTableData(data);
  };

  const filterCountTableData = (data) => {
    const result = {};
    var lastYear = new Date().getFullYear() - 1;

    if (data.acadYear) {
      lastYear = data.acadYear;
    }
    // Loop through last 5 years records.
    for (let year = lastYear; year > lastYear - 5; year--) {
      const yearObject = {};
      if (data && data.content && data.content[year]) {
        data.content[year].map((yearData, index) => {
          // Hide the UNDERREPRESENTED data from UI
          // Jira Link TGDS-4754(https://stanfordhospital.atlassian.net/browse/TGDS-4754)

          if (
            yearData.tableSixBCharacteristicType ===
            "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS"
          )
            return null;
          return (yearObject[index] = makeRowObject(yearData));
        });
      } else {
        // Assign Empty opbject if no data found for selected year.
        yearObject[0] = {
          degreeTypeForSixBCount: "TOTAL",
          totalApplicantPool: 0,
          applicantsEligibleForSupport: 0,
          newEntrantsToProgram: 0,
          newEntrantsEligibleForSupport: 0,
          newEntrantsAppointed: null,
        };
      }
      // preventing automatic sort of Object numeric property by adding " ".
      result[" " + year] = yearObject;
    }

    // Add the summary data
    const summaryObject = {};
    if (data.content && data.content["summary"]) {
      data.content["summary"].map((summaryData, index) => {
        if (
          summaryData.tableSixBCharacteristicType ===
          "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS"
        ) {
          // Hide the UNDERREPRESENTED data from UI
          // Jira Link TGDS-4754(https://stanfordhospital.atlassian.net/browse/TGDS-4754)

          return (summaryObject[index] = {});
          // summaryObject[some_index] is getting assigned in every case!
          // return (summaryObject[index] = {
          //   degreeTypeForSixBCount: t(
          //     `${tableColumnLabel}.characteristics.summary.PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS`
          //   ),
          //   totalApplicantPool: summaryData.totalApplicantPool ?? "",
          //   applicantsEligibleForSupport:
          //     summaryData.applicantsEligibleForSupport ?? "",
          //   newEntrantsToProgram: `${
          //     summaryData.percentWithUnderrepresentedNewEntrantsToProgram ?? 0
          //   } %`,
          //   newEntrantsEligibleForSupport: `${
          //     summaryData.percentWithUnderrepresentedNewEntrantsEligibleForSupport ??
          //     0
          //   } %`,
          //   newEntrantsAppointed: summaryData.newEntrantsAppointed ?? null,
          // });
        } else {
          return (summaryObject[index] = {
            degreeTypeForSixBCount: degreeEnumMap(summaryData.degreeType) ?? "",
            totalApplicantPool: summaryData.totalApplicantPool ?? 0,
            applicantsEligibleForSupport:
              summaryData.applicantsEligibleForSupport ?? 0,
            newEntrantsToProgram: summaryData.newEntrantsToProgram ?? 0,
            newEntrantsEligibleForSupport:
              summaryData.newEntrantsEligibleForSupport ?? 0,
            newEntrantsAppointed: summaryData.newEntrantsAppointed ?? null,
          });
        }
      });
    } else {
      // Assign Empty object if no data found for selected year.
      summaryObject[0] = {
        degreeTypeForSixBCount: "MEAN",
        totalApplicantPool: 0,
        applicantsEligibleForSupport: 0,
        newEntrantsToProgram: 0,
        newEntrantsEligibleForSupport: 0,
        newEntrantsAppointed: null,
      };
    }

    result["summary"] = summaryObject;

    return result;
  };

  /**
   * Integer-valued cells are linked most of the time.
   * The cells of the TOTAL should not be linked.
   *
   * @param data
   *   Response data received from API.
   */
  const makeRowObject = (rowData) => {
    // Hide the UNDERREPRESENTED data from UI
    // Jira Link TGDS-4754(https://stanfordhospital.atlassian.net/browse/TGDS-4754)

    // if (
    //   rowData.tableSixBCharacteristicType ===
    //   "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS"
    // ) {
    // return {
    //   degreeTypeForSixBCount: t(
    //     `${tableColumnLabel}.characteristics.PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS`
    //   ),
    //   totalApplicantPool: rowData.totalApplicantPool ?? "",
    //   applicantsEligibleForSupport:
    //     rowData.applicantsEligibleForSupport ?? "",
    //   newEntrantsToProgram: `${
    //     rowData.percentWithUnderrepresentedNewEntrantsToProgram ?? 0
    //   } %`,
    //   newEntrantsEligibleForSupport: `${
    //     rowData.percentWithUnderrepresentedNewEntrantsEligibleForSupport ?? 0
    //   } %`,
    // };
    // } else {
    switch (rowData.degreeType) {
      case "TOTAL":
        return {
          degreeTypeForSixBCount: degreeEnumMap(rowData.degreeType) ?? "",
          totalApplicantPool: `${rowData.totalApplicantPool ?? 0}`,
          applicantsEligibleForSupport: `${
            rowData.applicantsEligibleForSupport ?? 0
          }`,
          newEntrantsToProgram: `${rowData.newEntrantsToProgram ?? 0}`,
          newEntrantsEligibleForSupport: `${
            rowData.newEntrantsEligibleForSupport ?? 0
          }`,
          newEntrantsAppointed: `${rowData.newEntrantsAppointed ?? ""}`,
        };
      default:
        return {
          degreeTypeForSixBCount: degreeEnumMap(rowData.degreeType) ?? "",
          totalApplicantPool: rowData.totalApplicantPool ?? 0,
          applicantsEligibleForSupport:
            rowData.applicantsEligibleForSupport ?? 0,
          newEntrantsToProgram: rowData.newEntrantsToProgram ?? 0,
          newEntrantsEligibleForSupport:
            rowData.newEntrantsEligibleForSupport ?? 0,
          newEntrantsAppointed: rowData.newEntrantsAppointed ?? null,
        };
    }
    // }
  };

  let part1Header = {};
  const tableHeader = (index, year) => {
    let firstHeader;
    if (index === "1") {
      firstHeader = t(`${tableColumnLabel}.counts.mostRecentlyCompletedYear`, {
        recentCompletionYear: `${year}`,
      });
    } else if (index === "summary") {
      firstHeader = t(`${tableColumnLabel}.counts.summaryTable`);
    } else {
      firstHeader = t(`${tableColumnLabel}.counts.previousYear`, {
        previousCompletionYear: `${year}`,
      });
    }
    part1Header = {
      mostRecentlyCompletedYear: firstHeader,
      totalApplicantPool: t(`${tableColumnLabel}.counts.totalApplicantPool`),
      applicantsEligibleForSupport: t(
        `${tableColumnLabel}.counts.applicantsEligibleForSupport`
      ),
      newEntrantsToTheProgram: t(
        `${tableColumnLabel}.counts.newEntrantsToProgram`
      ),
      newEntrantsEligibleForSupport: t(
        `${tableColumnLabel}.counts.newEntrantsEligibleForSupport`
      ),
      newEntrantsAppointed: t(
        `${tableColumnLabel}.counts.newEntrantsAppointed`
      ),
    };
  };

  // Build Tab Data
  const tabType = "table-six-b";

  useEffect(() => {
    getOutputTable6B(
      selectedSubmission,
      tabType,
      setTable6BData,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [selectedSubmission, tabType]);

  const tabBody = filterTableData(table6BData);
  let combineTableData = null;
  if (loading) {
    combineTableData = (
      <>
        <CustomTable
          key={0}
          header={{ loading: t("globals.list.messages.fetchingInfo") }}
          body={{}}
          tableClass={outputTableStyles.tableOne}
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{ department: "name" }}
        />
      </>
    );
  }
  if (!loading && tabBody && table6BData) {
    combineTableData = Object.entries(tabBody).map((response, index) => {
      const key = response[0].trim();
      let label = null;
      if (!isNaN(key)) {
        const dataYear = Number(response[0].trim());
        const dataPrevYear = Number(response[0].trim()) - 1;

        label = dataYear;
        if (index === 0) {
          tableHeader("1", dataPrevYear + "-" + dataYear);
        } else {
          tableHeader("2", dataPrevYear + "-" + dataYear);
        }
      } else {
        label = "summary";
        tableHeader("summary", "");
      }

      return (
        <CustomTable
          key={index}
          sticky
          label={label}
          header={part1Header}
          body={response[1]}
          tableClass={outputTableStyles.tableOne}
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{ department: "name" }}
        />
      );
    });
  }

  /**
   * API call for popup
   */
  useEffect(() => {
    getOutputTable6BPopUp(
      getSelectedSubmission(),
      getYear,
      outputTableSixBEnumFilter,
      degreeCode,
      setPopupData,
      setAlert,
      clearAlert,
      setDialogLoading
    );
    // eslint-disable-next-line
  }, [getSelectedSubmission, getYear, degreeCode, outputTableSixBEnumFilter]);

  // Build Modal Box data
  let dialogBox = null;
  const modalBoxBody = filterTable1PopUpData(popupData);
  if (open) {
    const modalHeader = {
      "#": "#",
      name: t("output.modalDialog.list.tableColumns.name"),
      sunetId: t("output.modalDialog.list.tableColumns.sunetId"),
    };
    const modalTitle =
      degreeCode +
      " - " +
      t(`${tableColumnLabel}.counts.` + outputTableSixBEnumFilter);
    dialogBox = (
      <CustomDialog
        closeButtonText="CLOSE"
        title={modalTitle}
        open={open}
        handleClose={handleCloseHandler}
      >
        <CustomTable
          sticky
          label={modalTitle}
          header={dialogLoading ? {} : modalHeader}
          body={modalBoxBody}
          tableClass="modalBox"
          loading={dialogLoading}
        />
      </CustomDialog>
    );
  }
  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      "Table_6B",
      "table-six-b",
      getSelectedSubmission(),
      setAlert,
      clearAlert,
      setExportLoading
    );
  };

  return (
    <MuiGrid container direction="column">
      <Spinner
        size="50px"
        visible={loading}
        style={sharedStyles.pageSpinnerStyles}
      />
      <MuiGrid sx={sharedStyles.outputAutoCompleteWrapper}>
        {/* Submission Select Box */}
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid container justifyContent="flex-end">
        {/* Export Button */}
        <MuiButton
          variant="outlined"
          color="secondary"
          onClick={handleExport}
          disabled={exportLoading}
        >
          <Spinner
            visible={exportLoading}
            style={sharedStyles.exportSpinnerStyles}
          />
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}
        </MuiButton>
      </MuiGrid>

      <MuiGrid sx={outputTableStyles.container}>
        {/* Table 6 Output */}
        {combineTableData}
        {/* Dialog Box Output */}
        {dialogBox}
      </MuiGrid>
    </MuiGrid>
  );
};
