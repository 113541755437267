import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid2 as MuiGrid, Button as MuiButton } from "@mui/material";
import { CloudDownload as MuiCloudDownloadIcon } from "@mui/icons-material";
/** Custom components */
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import CustomTable from "app/shared/ui/Table/Table";
import CustomDialog from "app/shared/ui/Dialog/Dialog";
import { Spinner } from "app/shared/ui/Loading/Spinner";
/** Services */
import {
  getOutputSubmissionsList,
  getOutputTable6A,
  getOutputTable6APopUp,
  getExportData,
} from "app/services/outputService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
/* Context and Component to show the error on UI */
import { useAlerts } from "common";
/* Styles */
import { outputTableStyles } from "./OutputTable6A.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

/**
 * Filter Table1 Pop up Data.
 *
 * @param data
 *   Response pop-up data received from API.
 */
const filterTable1PopUpData = (data) => {
  const response = {};
  // Check if data returned from API Call.
  if (data && data.length) {
    data.map((record) => {
      return (response[record["traineeDto"]["rowId"]] = {
        "#": record["traineeDto"]["rowId"] ?? "",
        name: record["traineeDto"]["fullName"] ?? "",
        sunetId: record["traineeDto"]["sunetId"] ?? "",
      });
    });
  }
  return response;
};

export const Table6A = (props) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [outputTableOneEnumFilter, setOutputTableOneEnumFilter] =
    useState(null);
  const [getYear, setYear] = useState(null);
  const [departmentData, setDepartmentData] = useState({
    name: null,
    code: null,
  });
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { handleSubmissionUpdate } = useSelectedSubmission();
  const [popupData, setPopupData] = useState([]);
  const { setAlert, clearAlert } = useAlerts();
  const [loading, setLoading] = useState(true);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const selectedSubmission = getSelectedSubmission();

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    handleSubmissionUpdate();
  };

  /**
   * Open Modal Box or Perform any other linking functionality.
   *
   * @param rowId
   *   Table Row ID.
   * @param outputTableOneEnumFilter
   *   Table enum filter.
   * @param year
   *   Year.
   * @param departmentData
   *   Department Data.
   */
  const table1ModalOpenEventHandler = (
    rowId,
    outputTableOneEnumFilter,
    year,
    departmentData
  ) => {
    const [, { department }] = departmentData;
    setOpen(true);
    setOutputTableOneEnumFilter(outputTableOneEnumFilter);
    setYear(year);
    setDepartmentData({ name: department["name"], code: department["code"] });
  };

  /**
   * Dialog Close Button Handler.
   */
  const handleCloseHandler = () => {
    setOpen(false);
  };

  /**
   * Filter Table Data.
   *
   * @param data
   *   Response data received from API.
   */
  const filterTableData = (data) => {
    return filterCountTableData(data);
  };

  /**
   * Filter Count Table Data.
   *
   * @param data
   *   Response data received from API.
   */
  const filterCountTableData = (data) => {
    const result = {};
    let lastYear = new Date().getFullYear() - 1;

    if (data.acadYear) {
      lastYear = data.acadYear;
    }
    // Loop through last 5 years records.
    for (let year = lastYear; year > lastYear - 5; year--) {
      const yearObject = {};
      if (data && data.content && data?.content[year]) {
        // Hide the UNDERREPRESENTED data from UI
        // Jira Link TGDS-4754(https://stanfordhospital.atlassian.net/browse/TGDS-4754)

        data.content[year].map((yearData, index) => {
          if (
            yearData.department?.code ===
            "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS"
          )
            return null;
          return (yearObject[index] = makeRowObject(yearData));
        });
      } else {
        // Assign Empty opbject if no data found for selected year.
        yearObject[0] = {
          department: {
            code: "TOTAL",
            name: "TOTAL",
          },
          totalApplicantPool: "0",
          applicantsEligibleForSupport: "0",
          newEntrantsToProgram: "0",
          newEntrantsEligibleForSupport: "0",
          newEntrantsAppointed: null,
        };
      }
      // preventing automatic sort of Object numeric property by adding " ".
      result[" " + year] = yearObject;
    }

    // Add the summary data
    const summaryObject = {};
    if (data.content && data.content["summary"]) {
      data.content["summary"].map((summaryData, index) => {
        // Hide the UNDERREPRESENTED data from UI
        if (
          summaryData.department?.code ===
          "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS"
        )
          return null;
        return (summaryObject[index] = makeRowObject(summaryData));
      });
    } else {
      // Assign Empty object if no data found for selected year.
      summaryObject[0] = {
        department: {
          code: "MEAN",
          name: "Mean Count Across Years",
        },
        totalApplicantPool: 0,
        applicantsEligibleForSupport: 0,
        newEntrantsToProgram: 0,
        newEntrantsEligibleForSupport: 0,
        newEntrantsAppointed: null,
      };
    }

    result["summary"] = summaryObject;

    return result;
  };

  /**
   * Integer-valued cells are linked most of the time.
   * The cells of the TOTAL should not be linked.
   * They won't be if they are string-valued.
   *
   * @param data
   *   Response data received from API.
   */
  const makeRowObject = (rowData) => {
    switch (rowData.department?.code) {
      case "TOTAL":
        return {
          department: rowData.department ?? "",
          totalApplicantPool: `${rowData.totalApplicantPool ?? 0}`,
          applicantsEligibleForSupport: `${
            rowData.applicantsEligibleForSupport ?? 0
          }`,
          newEntrantsToProgram: `${rowData.newEntrantsToProgram ?? 0}`,
          newEntrantsEligibleForSupport: `${
            rowData.newEntrantsEligibleForSupport ?? 0
          }`,
          newEntrantsAppointed: `${rowData.newEntrantsAppointed ?? ""}`,
        };
      // Hide the UNDERREPRESENTED data from UI
      // Jira Link TGDS-4754(https://stanfordhospital.atlassian.net/browse/TGDS-4754)

      // case "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS":
      //   return {
      //     department: rowData.department ?? "",
      //     totalApplicantPool: rowData.totalApplicantPool ?? "",
      //     applicantsEligibleForSupport:
      //       rowData.applicantsEligibleForSupport ?? "",
      //     newEntrantsToProgram: `${rowData.newEntrantsToProgram ?? 0} %`,
      //     newEntrantsEligibleForSupport: `${
      //       rowData.newEntrantsEligibleForSupport ?? 0
      //     } %`,
      //   };
      default:
        return {
          department: rowData.department ?? "",
          totalApplicantPool: rowData.totalApplicantPool ?? 0,
          applicantsEligibleForSupport:
            rowData.applicantsEligibleForSupport ?? 0,
          newEntrantsToProgram: rowData.newEntrantsToProgram ?? 0,
          newEntrantsEligibleForSupport:
            rowData.newEntrantsEligibleForSupport ?? 0,
          newEntrantsAppointed: rowData.newEntrantsAppointed ?? null,
        };
    }
  };

  let header = {};
  const tabe6Label = "output.mainView.list.tableColumns.table6A";

  const tableHeader = (index, year) => {
    let firstHeader;
    if (index === "1") {
      firstHeader = t(`${tabe6Label}.counts.mostRecentlyCompletedYear`, {
        recentCompletionYear: `${year}`,
      });
    } else if (index === "summary") {
      firstHeader = t(`${tabe6Label}.counts.summaryTable`);
    } else {
      firstHeader = t(`${tabe6Label}.counts.previousYear`, {
        previousCompletionYear: `${year}`,
      });
    }
    header = {
      mostRecentlyCompletedYear: firstHeader,
      totalApplicantPool: t(`${tabe6Label}.counts.totalApplicantPool`),
      applicantsEligibleForSupport: t(
        `${tabe6Label}.counts.applicantsEligibleForSupport`
      ),
      newEntrantsToProgram: t(`${tabe6Label}.counts.newEntrantsToProgram`),
      newEntrantsEligibleForSupport: t(
        `${tabe6Label}.counts.newEntrantsEligibleForSupport`
      ),
      newEntrantsAppointed: t(`${tabe6Label}.counts.newEntrantsAppointed`),
    };
  };
  const [table6AData, setTable6AData] = useState([]);

  useEffect(() => {
    getOutputTable6A(
      selectedSubmission,
      "table-six-a",
      setTable6AData,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [selectedSubmission]);

  const tabBody = filterTableData(table6AData);
  let combineTableData = null;
  if (loading) {
    combineTableData = (
      <>
        <CustomTable
          key={0}
          header={{ loading: t("globals.list.messages.fetchingInfo") }}
          body={{}}
          tableClass={outputTableStyles.tableOne}
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{ department: "name" }}
        />
      </>
    );
  }
  if (!loading && tabBody) {
    combineTableData = Object.entries(tabBody).map((response, index) => {
      const key = response[0].trim();
      let label = null;
      if (!isNaN(key)) {
        const dataYear = Number(key);
        const dataPrevYear = Number(key) - 1;

        label = dataYear;
        if (index === 0) {
          tableHeader("1", dataPrevYear + "-" + dataYear);
        } else {
          tableHeader("2", dataPrevYear + "-" + dataYear);
        }
      } else {
        label = "summary";
        tableHeader("summary", "");
      }

      return (
        <CustomTable
          key={encodeURIComponent(index)}
          sticky
          label={label}
          header={header}
          body={response[1]}
          tableClass={outputTableStyles.tableOne}
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{ department: "name" }}
        />
      );
    });
  }

  /**
   * API call for popup
   */
  useEffect(() => {
    getOutputTable6APopUp(
      getSelectedSubmission(),
      getYear,
      outputTableOneEnumFilter,
      departmentData["code"],
      setPopupData,
      setAlert,
      clearAlert,
      setDialogLoading
    );
    // eslint-disable-next-line
  }, [
    getSelectedSubmission,
    getYear,
    outputTableOneEnumFilter,
    departmentData,
  ]);

  // Build Modal Box data
  let dialogBox = null;
  const modalBoxBody = filterTable1PopUpData(popupData);

  if (open) {
    const modalHeader = {
      "#": "#",
      name: t("output.modalDialog.list.tableColumns.name"),
      sunetId: t("output.modalDialog.list.tableColumns.sunetId"),
    };
    const modalTitle =
      departmentData["name"] +
      " - " +
      t(`${tabe6Label}.counts.` + outputTableOneEnumFilter);
    dialogBox = (
      <CustomDialog
        closeButtonText="CLOSE"
        title={modalTitle}
        open={open}
        handleClose={handleCloseHandler}
      >
        <CustomTable
          sticky
          label={modalTitle}
          header={dialogLoading ? {} : modalHeader}
          body={modalBoxBody}
          tableClass="modalBox"
          loading={dialogLoading}
        />
      </CustomDialog>
    );
  }

  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      "Table_6A",
      "table-six-a",
      getSelectedSubmission(),
      setAlert,
      clearAlert,
      setExportLoading
    );
  };

  return (
    <MuiGrid container direction="column">
      <Spinner
        size="50px"
        visible={loading}
        style={sharedStyles.pageSpinnerStyles}
      />
      <MuiGrid sx={sharedStyles.outputAutoCompleteWrapper}>
        {/* Submission Select Box */}
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid container justifyContent="flex-end">
        {/* Export Button */}
        <MuiButton
          variant="outlined"
          color="secondary"
          onClick={handleExport}
          disabled={exportLoading}
        >
          <Spinner
            visible={exportLoading}
            style={sharedStyles.exportSpinnerStyles}
          />
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}
        </MuiButton>
      </MuiGrid>

      <MuiGrid sx={outputTableStyles.container}>
        {/* Table 6 Output */}
        {combineTableData}
        {/* Dialog Box Output */}
        {dialogBox}
      </MuiGrid>
    </MuiGrid>
  );
};
